.stock-price-change-chart {
    display: flex;
    border-radius: 8px;
    padding: 0.5rem 1rem;
    margin: 3rem 0;
    color: #ffffff;
    max-width: 100%;
    overflow-x: auto;
}

.stock-price-change-chart ul {
    display: flex;
    list-style:armenian;
    padding: 0;
    margin: 0;
    gap: 1.4rem;
    justify-content: space-between;
    width: 100%;
}

.stock-price-change-chart li {
    display: flex;
    flex-direction: column;
    font-size: 1.5rem; /* Larger font for readability */
}

.stock-price-change-chart li span.value {
    font-weight: bold;
    margin-top: 0.3rem;
    font-size: 1.7rem; /* Larger font size for values */
    padding-top: 0.3vh;
}

.stock-price-change-chart li.positive span.value {
    color: var(--green);
}

.stock-price-change-chart li.negative span.value {
    color: var(--red);
}

@media (max-width: 768px) {
    .stock-price-change-chart ul {
        gap: 1rem;
        flex-wrap: wrap; /* Allow wrapping for smaller screens */
    }

    .stock-price-change-chart li {
        font-size: 1.4rem;
    }

    .stock-price-change-chart li span.value {
        font-size: 1.8rem;
    }
}
