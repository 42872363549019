.custom-select:after {
    content: "\25BC";
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    color: #333;
    pointer-events: none;
    font-size: 1.5rem;
}

.custom-select:hover {
    border-color: #888;
}

.custom-select select:focus {
    outline: none;
}

.stock-details {
    padding: 2rem 3rem;
}

.stock-details__title {
    color: var(--white);
    font-weight: 00;
    text-align: center;

    /* responsive */

    margin-top: 0;
    margin-bottom: 4.8rem;

    font-size: 2rem;
    line-height: 3.2rem;
}

.stock-details__grid {
    display: flex;
    gap: 1rem;
    overflow-x: auto;
    white-space: nowrap; 
    padding: 1rem 0;
}

.stock-details__grid > * {
	flex: 0 auto;
	min-width: 350px;
	margin-right: 1rem;
}

.stock-details__grid::-webkit-scrollbar {
	height: 1.5rem;
}

.stock-details__grid::-webkit-scrollbar-thumb:active {
    background-color: #808080;
}

.stock-details__grid::-webkit-scrollbar-track {
	background: var(--black);
}

.stock-details__grid::-webkit-scrollbar-thumb {
	background-color: var(--white); 
	border-radius: 10px;
	border: 2px solid var(--black); 
}


.select-container {
    display: flex;
    justify-content: center;
}


.select-label {
    font-size: 2rem;
    font-weight: bold;
    margin: auto;
}

.select-label-quarters {
    font-size: 2rem;
    display: flex;
    align-items: center;
    font-weight: bold;
}

.toggle-button {
    display: inline-block;
    padding: 10px 20px;
    margin: 5px 0;
    margin-top: 30px;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    background-color: gray;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.custom-select {
	display: inline-block;
	width: 10vw;
	height: 3vh;
	margin: 1rem;
	border: 1px solid #ccc;
	border-radius: 4px;
	color: var(--black);
	background-color: white;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	overflow: hidden;
	font-size: 1.5em;
	text-align: center;
}


@media only screen and (max-width: 480px) {
	.select-label {
		font-size: 1.5em;
		font-weight: bold;
		margin-right: 10px;
	}
    .custom-select {
        display: inline-block;
        width: 10vw;
        height: 5vh;
        margin: 5px;
        border: 1px solid #ccc;
        border-radius: 4px;
        color: #333;
        background-color: white;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        overflow: hidden;
        font-size: 1.7em;
        text-align: center;
    }

    .stock-details {
        padding: 1.6rem;
    }

    .stock-details__title {
        font-size: 2rem;
        line-height: 2.8rem;
        margin-bottom: 2.4rem;
    }

    .stock-details__grid {
        display: flex;
        gap: 1rem;
        overflow-x: auto; /* Enable horizontal scrolling */
        white-space: nowrap; /* Prevent items from wrapping */
        padding: 1rem 0;
    }

    .stock-details__grid > * {
        min-width: 250px; /* Adjust the minimum width for smaller screens */
    }
}

@media only screen and (max-width: 900px) {
    .custom-select {
        display: inline-block;
        width: 10vw;
        height: 5vh;
        margin: 5px;
        border: 1px solid #ccc;
        border-radius: 4px;
        color: #333;
        background-color: white;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        overflow: hidden;
        font-size: 1.7em;
        text-align: center;
    }
    .stock-details__grid {
        display: flex;
        gap: 1rem;
        overflow-x: auto; /* Enable horizontal scrolling */
        white-space: nowrap; /* Prevent items from wrapping */
        padding: 1rem 0;
    }

    .stock-details__grid > * {
        min-width: 300px; /* Adjust the minimum width for medium screens */
    }
}