@import url(https://fonts.googleapis.com/css?family=Roboto:400,700);

.loading-dots-wrapper {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
	position: relative;
	bottom: 1.1vh;
}
.h1-loading {
	font-size: 1em;
	font-family: "Roboto", sans-serif;
	color: white;
	font-weight: 700;
}

.span[class^="dot-"] {
	opacity: 0;
}
.dot-one {
	color: white;
	animation: dot-one 2s infinite linear;
}
.dot-two {
	color: white;
	animation: dot-two 2s infinite linear;
}
.dot-three {
	color: white;
	animation: dot-three 2s infinite linear;
}
@keyframes dot-one {
	0% {
		opacity: 0;
	}
	15% {
		opacity: 0;
	}
	25% {
		opacity: 1;
	}
	100% {
		opacity: 1;
	}
}

@keyframes dot-two {
	0% {
		opacity: 0;
	}
	25% {
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 1;
	}
}

@keyframes dot-three {
	0% {
		opacity: 0;
	}
	50% {
		opacity: 0;
	}
	75% {
		opacity: 1;
	}
	100% {
		opacity: 1;
	}
}
