@import url("https://fonts.googleapis.com/css2?family=Fira+Code:wght@400;600&family=Inter:wght@400;500;600;700&family=Space+Grotesk:wght@400;500;600&display=swap");

/* Theme */

:root {
	--white: #F8FAFC;
	--black: #171717;
	--gray: #121620;
	/* --gray: #171717; */
	/* --gray: #262626; */
	--dark-gray: #1C1C1C;
	--light-gray: #404040;
	--lighter-gray: #94A3B8;
	--light-dark-blue: #415a77;
	--dark-dark-blue: #1b263b;
	--yellow: #534920;
	--red: #EF4444;
	--dark-red: #291818;
	--green: #22C55E;
	--orange: #F59E0B;
	--light-blue: #1E293B;
	--lighter-blue: #374151;
	--blue: #00c8e3;
	--teal: #00c8e3;
	/* --blue: #048fdd; */
	--dark-blue: #334155;

    --primary-gradient: linear-gradient(90deg, #00E5FF 0%, #00B8D4 100%);
    --text-gradient: linear-gradient(
        to right,
        #00E5FF 10%,
        #7FFFFF 80%
    );
    --text-gradient-secondary: linear-gradient(
        to right,
        #7FFFFF 40%,
		#00c8e3 100%

    );

    --background-glow: 0 0 20px rgba(0, 229, 255, 0.15);

    --card-background: rgba(255, 255, 255, 0.05);
    --text-secondary: rgba(255, 255, 255, 0.7);

	/* Blues & Dark Gradients (Professional) */
	--gradient-blue-dark: linear-gradient(135deg, #0284C7 0%, #1E293B 100%);
	--gradient-blue-accent: linear-gradient(135deg, #3B82F6 0%, #0EA5E9 100%);
	--gradient-dark-subtle: linear-gradient(180deg, #1E293B 0%, #121620 100%);

	/* Data Visualization Gradients */
	--gradient-success: linear-gradient(135deg, #22C55E 0%, #10B981 100%);
	--gradient-warning: linear-gradient(135deg, #F59E0B 0%, #FBBF24 100%);
	--gradient-danger: linear-gradient(135deg, #EF4444 0%, #DC2626 100%);

	/* Card & Element Gradients */
	--gradient-card-hover: linear-gradient(180deg, rgba(30, 41, 59, 0.8) 0%, rgba(18, 22, 32, 0.9) 100%);
	--gradient-highlight: linear-gradient(90deg, rgba(59, 130, 246, 0.1) 0%, rgba(14, 165, 233, 0) 100%);
	--gradient-dark-glass: linear-gradient(120deg, rgba(30, 41, 59, 0.7) 0%, rgba(51, 65, 85, 0.4) 100%);

	/* Accent Gradients */
	--gradient-blue-to-teal: linear-gradient(135deg, #2563EB 0%, #0EA5E9 100%);
	--gradient-dark-blue-pro: linear-gradient(90deg, #0F172A 0%, #1E293B 100%);
	--gradient-premium: linear-gradient(135deg, #3B82F6 0%, #8B5CF6 100%);

	--yellow-mandarine-gradient: linear-gradient(
		153.07deg,
		var(--yellow) -2.47%,
		var(--mandarine) 102.78%
	);

	--mandarine-orange-gradient: linear-gradient(
		153.07deg,
		var(--mandarine) -2.47%,
		var(--orange) 102.78%
	);

	--pink-yellow-gradient: linear-gradient(
		153.07deg,
		var(--pink) -2.47%,
		var(--yellow) 102.78%
	);

	--pink-indigo-gradient: linear-gradient(
		153.07deg,
		var(--pink) -2.47%,
		var(--indigo) 102.78%
	);

	--indigo-aqua-gradient: linear-gradient(
		153.07deg,
		var(--indigo) -2.47%,
		var(--aqua) 102.78%
	);

	--blue-aqua-gradient: linear-gradient(
		153.07deg,
		var(--blue) -2.47%,
		var(--aqua) 102.78%
	);

	--aqua-emerald-gradient: linear-gradient(
		153.07deg,
		var(--aqua) -2.47%,
		var(--emerald) 102.78%
	);

	--emerald-yellow-gradient: linear-gradient(
		153.07deg,
		var(--emerald) -2.47%,
		var(--yellow) 102.78%
	);

	--font-primary: "Space Grotesk", sans-serif;
	--font-secondary: "Inter", sans-serif;
	--font-mono: "Fira Code", monospace;
}

/* [data-theme='dark'] {
	--gray: #fff;
	--white: #262626;
  } */
