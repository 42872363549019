.stock-filings {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
}

.stock-filings__title {
    font-size: 3.6rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 1rem;
    background: linear-gradient(
        to right,
        #F8FAFC 20%,
        var(--dark-gray) 90%,
        #F8FAFC 100%
    );
    background-size: 200% auto;
    color: #000;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    letter-spacing: -0.02em;
}

.hero-banner__headline__3 {
    font-size: 1.6rem;
    color: rgba(248, 250, 252, 0.7);
    text-align: center;
    margin-bottom: 4rem;
    font-weight: 400;
    letter-spacing: 0.01em;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    line-height: 1.6;
}

@keyframes shine {
    to {
        background-position: 200% center;
    }
}

.stockFilingCard-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
    column-gap: 3.2rem;
    row-gap: 4.8rem;
    padding: 2rem;
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
}

.yearly-scores-title {
    color: var(--text-secondary);
    margin-bottom: 2rem;
    font-size: 1.8rem;
    text-align: center;
}

/* Tablet and smaller desktop */
@media only screen and (max-width: 1024px) {
    .stockFilingCard-wrapper {
        grid-template-columns: repeat(2, 1fr);
        row-gap: 4rem;
    }

    .stock-filings__title {
        font-size: 3rem;
    }
}

/* Mobile landscape and tablet portrait */
@media only screen and (max-width: 760px) {
    .stock-filings {
        padding: 1.6rem;
    }

    .stock-filings__title {
        font-size: 2.6rem;
    }

    .hero-banner__headline__3 {
        font-size: 1.4rem;
        margin-bottom: 3rem;
    }

    .stockFilingCard-wrapper {
        grid-template-columns: 1fr;
        column-gap: 1.6rem;
        row-gap: 3.2rem;
        padding: 0.5rem;
    }

    .yearly-scores-title {
        font-size: 1.6rem;
    }
}

/* Mobile portrait */
@media only screen and (max-width: 480px) {
    .stock-filings {
        padding: 1.2rem;
    }

    .stock-filings__title {
        font-size: 2.2rem;
    }

    .hero-banner__headline__3 {
        font-size: 1.3rem;
        margin-bottom: 2.5rem;
    }

    .yearly-scores-title {
        font-size: 1.4rem;
    }
}

/*

.error-message {
    color: var(--error-color);
    text-align: center;
    margin-top: 1rem;
} */