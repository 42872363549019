.profile-page__title {
    text-align: center;
    margin-bottom: 4rem;
    background: linear-gradient(to right, var(--white) 20%, var(--teal) 50%, var(--white) 80%);
    background-size: 200% auto;
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
}

.profile-page__container {
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;
    background: var(--darker-blue);
    border-radius: 12px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
}

.profile-page__header {
    display: flex;
    align-items: center;
    gap: 2.4rem;
    padding-bottom: 2.4rem;
    border-bottom: 1px solid var(--border-color);
    margin-bottom: 2.4rem;
}

.profile-page__avatar-container {
    flex-shrink: 0;
}

.profile-page__avatar {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    border: 3px solid var(--teal);
    object-fit: cover;
}

.profile-page__avatar-placeholder {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background: var(--darker-blue);
    border: 3px solid var(--teal);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white);
    font-weight: 600;
    font-size: 3.6rem;
}

.profile-page__user-info {
    flex-grow: 1;
}

.profile-page__name {
    font-size: 2.8rem;
    font-weight: 600;
    color: var(--white);
    margin-bottom: 0.8rem;
}

.profile-page__email {
    font-size: 1.6rem;
    color: var(--text-secondary);
    margin: 0;
}

.profile-page__section {
    padding: 2.4rem;
    background: var(--darker-blue-hover);
    border-radius: 8px;
    margin-bottom: 2rem;
}

.profile-page__section-title {
    font-size: 1.8rem;
    font-weight: 600;
    color: var(--white);
    margin-bottom: 2rem;
}

.profile-page__subscription-info {
    display: grid;
    gap: 1.6rem;
}

.profile-page__info-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.2rem;
    background: var(--darker-blue);
    border-radius: 6px;
}

.profile-page__info-label {
    font-size: 1.4rem;
    color: var(--text-secondary);
}

.profile-page__info-value {
    font-size: 1.4rem;
    font-weight: 500;
    padding: 0.4rem 1.2rem;
    border-radius: 4px;
}

.profile-page__info-value--plan {
    background: var(--teal-transparent);
    color: var(--teal);
}

.profile-page__info-value--success {
    background: var(--success-transparent);
    color: var(--success);
}

.profile-page__info-value--warning {
    background: var(--warning-transparent);
    color: var(--warning);
}

.profile-page__info-value--error {
    background: var(--error-transparent);
    color: var(--error);
}

.profile-page__info-value--default {
    background: var(--darker-blue);
    color: var(--white);
}

.profile-page__upgrade-link {
    display: inline-block;
    padding: 1.2rem 2.4rem;
    background: var(--teal);
    color: var(--white);
    text-decoration: none;
    border-radius: 6px;
    font-weight: 600;
    font-size: 1.4rem;
    text-align: center;
    transition: background-color 0.2s ease;
    margin-top: 1.6rem;
}

.profile-page__upgrade-link:hover {
    background: var(--teal-hover);
}

@keyframes shine {
    0% {
        background-position: 200% center;
    }
    100% {
        background-position: -200% center;
    }
}

@media (max-width: 768px) {
    .profile-page__header {
        flex-direction: column;
        text-align: center;
        gap: 1.6rem;
    }

    .profile-page__container {
        padding: 1.6rem;
    }

    .profile-page__name {
        font-size: 2.4rem;
    }

    .profile-page__avatar,
    .profile-page__avatar-placeholder {
        width: 100px;
        height: 100px;
    }

    .profile-page__avatar-placeholder {
        font-size: 3rem;
    }
} 