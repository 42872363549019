.content-layout {
    max-width: 1400px;
    margin: 0 auto;
    padding: 2rem;
}

.content__title {
    font-size: 3.2rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 3rem;
    background: linear-gradient(to right, var(--white) 20%, var(--teal) 50%, var(--white) 80%);
    background-size: 200% auto;
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    animation: shine 3s ease-in-out infinite;
}

/* Overall Score Section */
.overall-score {
    border-radius: 12px;
    padding: 2rem;
    margin-bottom: 2.4rem;
    text-align: center;
    position: relative;
    overflow: hidden;
}


.overall-score__title {
    font-size: 2.4rem;
    color: var(--white);
    margin-bottom: 0.6rem;
}

.overall-score__value {
    font-size: 5rem;
    font-weight: 700;
    background: linear-gradient(135deg, var(--red) 0%, #FF6B6B 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    margin-bottom: 1rem;
}

/* Metrics Grid */
.metrics-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    gap: 1.6rem;
    margin-bottom: 3rem;
}

.metric-card {
    background: rgba(30, 41, 59, 0.4);
    border-radius: 8px;
    padding: 1.4rem;
    position: relative;
    overflow: hidden;
    transition: transform 0.3s ease;
    text-align: center;
}

.metric-card:hover {
}

.metric-card__label {
    font-size: 1.4rem;
    color: var(--text-secondary);
    margin-bottom: 0.6rem;
    text-transform: uppercase;
    letter-spacing: 0.05em;
}

.metric-card__value {
    font-size: 2.4rem;
    font-weight: 600;
}

.metric-card__value.green {
    color: var(--green);
}

.metric-card__value.red {
    color: var(--red);
}

.metric-card__value.neutral {
    color: var(--text-secondary);
}

/* Technical Indicators Table */
.indicators-table {
    background: rgba(30, 41, 59, 0.4);
    border-radius: 12px;
    margin-bottom: 4rem;
    overflow: hidden;
}

.indicators-table table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
}

.indicators-table th,
.indicators-table td {
    padding: 1.2rem 1.6rem;
    text-align: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.indicators-table th {
    background: rgba(30, 41, 59, 0.6);
    font-size: 1.4rem;
    font-weight: 500;
    color: var(--text-secondary);
    text-transform: uppercase;
    letter-spacing: 0.05em;
}

.indicators-table td {
    font-size: 1.6rem;
    color: var(--white);
}

/* Price Change Grid */
.price-change-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
    gap: 1.6rem;
    margin-bottom: 3rem;
}

.price-change-card {
    background: rgba(30, 41, 59, 0.4);
    border-radius: 8px;
    padding: 1.2rem;
    text-align: center;
    transition: transform 0.3s ease;
}

.price-change-card:hover {
    transform: translateY(-2px);
}

.price-change-card__period {
    font-size: 1.2rem;
    color: var(--text-secondary);
    margin-bottom: 0.6rem;
    text-transform: uppercase;
    letter-spacing: 0.05em;
}

.price-change-card__value {
    font-size: 1.8rem;
    font-weight: 600;
}

.price-change-card__value.positive {
    color: var(--green);
}

.price-change-card__value.negative {
    color: var(--red);
}

/* Section Headers */
.section-header {
    font-size: 2rem;
    font-weight: 600;
    color: var(--white);
    margin-bottom: 1.6rem;
    display: flex;
    align-items: center;
    gap: 1rem;
}

.section-header::before {
    content: '';
    display: block;
    width: 4px;
    height: 24px;
    background: var(--teal);
    border-radius: 2px;
}

/* Responsive Design */
@media (max-width: 1024px) {
    .metrics-grid {
        grid-template-columns: repeat(2, 1fr);
    }
    
    .price-change-grid {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 768px) {
    .content__title {
        font-size: 2.8rem;
    }

    .overall-score__value {
        font-size: 4rem;
    }

    .metrics-grid {
        grid-template-columns: 1fr;
    }

    .price-change-grid {
        grid-template-columns: repeat(2, 1fr);
    }

    .indicators-table {
        overflow-x: auto;
    }
}

@media (max-width: 480px) {
    .content-layout {
        padding: 1rem;
    }

    .content__title {
        font-size: 2.4rem;
    }

    .overall-score {
        padding: 2rem;
    }

    .overall-score__value {
        font-size: 3.6rem;
    }

    .price-change-grid {
        grid-template-columns: 1fr;
    }

    .metric-card,
    .price-change-card {
        padding: 1.6rem;
    }
}
