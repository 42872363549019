.product-display {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  margin: 0 auto;
  height: 30vh;
  cursor: pointer;
}

.product-display.selected {
  outline: 0.4rem solid #3885ff;
}

.product {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.description {
  text-align: center;
  font-size: 1.3rem;
}

.description h3 {
  margin: 0;
  color: #333;
}

.description h5 {
  margin: 5px 0 0;
  color: #666;
  font-size: 200px;
}

.plan-details {
  text-align: left;
  font-size: 16px;
  color: #666;
  margin-top: 10px;
}

.plan-details ul {
  list-style: none; /* Remove default list style */
  padding-left: 0;
  gap: 10px; /* Add gap between list items */
  display: flex;
  flex-direction: column;
}

.plan-details li {
  position: relative;
  padding-left: 20px; /* Add space for the custom dot */
}

.plan-details li::before {
  content: '•'; /* Custom dot */
  color: #007bff; /* Dot color */
  font-size: 1.5rem; /* Dot size */
  position: absolute;
  left: 0;
  top: 0;
  line-height: 1; /* Align dot vertically */
}
.plan-details li span {
  align-self: start; /* Align text vertically */
}

#checkout-and-portal-button {
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #6772e5;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

#checkout-and-portal-button:hover {
  background-color: #5469d4;
}