.top_ranked_content-layout {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    cursor: default;
    width: 100%;
}

.top_ranked_content__title {
    font-size: 2.4rem;
    color: #ffffff;
    margin-bottom: 30px;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1.5px;
}

.stock-item {
    padding: 10px 15px;
    margin: 10px 0;
    background-color: #1f2937;
    border-radius: 8px;
    color: #e5e7eb;
    font-size: 1.2rem;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
    cursor: pointer;
}

.stock-item:hover {
    transform: scale(1.03);
    background-color: #2d3748;
}

@media (max-width: 768px) {
    .top_ranked_content__title {
        font-size: 1.8rem;
    }
}