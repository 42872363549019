.button {
	border: 0;
	border-radius: 0.8rem;

	font-family: var(--font-primary);
	font-weight: 600;
	color: var(--white);

	background-color: var(--blue);
	cursor: pointer;
	user-select: none;

	transition: background 0.3s ease-out, color 0.3s ease-out;

	/* responsive */

	min-width: 10rem;
	padding: 1.6rem 1.6rem;

	font-size: 1.6rem;
	line-height: 2.4rem;
}

.button:hover {
	background: var(--white);
	color: var(--gray);
	border: none;
}

.button--compact {
	/* responsive */

	padding: 1rem 1.6rem;

	font-size: 1.6rem;
	line-height: 1.6rem;
}

.button--primary {
	background-color: var(--blue);
}

.button--primary:hover {
	background: var(--white);
	color: var(--gray);
	border: none;
}

.button--secondary {
	background-color: var(--white);
	color: var(--black);
}

.button--secondary:hover {
	background: var(--white);
	color: var(--gray);
	border: none;
}

@media only screen and (max-width: 480px) {
	.button {
		/* responsive */

		min-width: 0.8rem;
		padding: 1rem 1.2rem;

		font-size: 1.3rem;
		line-height: 2.2rem;
	}

	.button--compact {
		/* responsive */

		padding: 0.6rem 1rem;

		font-size: 1.3rem;
		line-height: 2rem;
	}
}

.button__login,
.button__logout {
	min-width: 8.4rem;
	color: var(--black);
	background: var(--white);
	width: 50%;
	font-size: 1.6rem;
	margin-right: 1.6rem;
	font-family: var(--font-primary);
	font-style: normal;
	font-weight: 600;
	line-height: 3.2rem;
	padding: 0.8rem 0;
	border-radius: 0.8rem;
	text-align: center;
	cursor: pointer;
	user-select: none;
	transition: all 0.2s ease;
}

.button__logout:hover,
.button__login:hover {
	background: var(--darker-blue);
	color: var(--white);
}

.button__sign-up {
	min-width: 8.4rem;

	border: 0.1rem solid var(--white);
	color: var(--white);
	background-color: transparent;
	width: 50%;
	font-size: 1.6rem;

	margin-right: 1.6rem;

	font-style: normal;
	font-weight: 500;
	line-height: 3.2rem;
	padding: 0.8rem 0;
	border-radius: 0.8rem;
	text-align: center;

	cursor: pointer;
	user-select: none;
	transition: background 0.3s ease-out, color 0.3s ease-out;
}


.button__login:last-child,
.button__logout:last-child,
.button__sign-up:last-child {
	margin-right: 0;
}

@media only screen and (min-width: 641px) {
	.button__login,
	.button__logout,
	.button__sign-up {
		padding: 0.8rem 1.2rem;

		font-size: 1.4rem;
		line-height: 1.6rem;
	}
}

.button__login,
.button__sign-up {
	font-size: 1.4rem;
	font-weight: 500;
	padding: 1rem 2.4rem;
	border-radius: 8px;
	cursor: pointer;
	transition: all 0.3s ease;
	display: inline-flex;
	align-items: center;
	gap: 0.8rem;
	white-space: nowrap;
	min-width: max-content;
	letter-spacing: 0.02em;
}

.button__login {
	background: rgba(30, 41, 59, 0.4);
	color: var(--text-secondary);
	border: 1px solid rgba(255, 255, 255, 0.1);
	position: relative;
	overflow: hidden;
}

.button__login:hover {
	background: rgba(30, 41, 59, 0.6);
	border-color: var(--teal);
	box-shadow: 0 4px 12px rgba(0, 229, 255, 0.1);
	color: var(--white);
}

.button__login:active {
	transform: translateY(0);
}

.button__sign-up {
	background: rgba(30, 41, 59, 0.4);
	color: var(--text-secondary);
	border: 1px solid var(--teal);
	position: relative;
	overflow: hidden;
}

.button__sign-up:hover {
	background: rgba(30, 41, 59, 0.2);
	color: var(--white);
	border-color: var(--teal);
	box-shadow: 0 4px 12px rgba(0, 229, 255, 0.15);
}

.button__sign-up:active {
	transform: translateY(0);
}

/* Responsive Design */
@media (max-width: 768px) {
	.button__login,
	.button__sign-up {
		font-size: 1.3rem;
		padding: 0.8rem 2rem;
	}
}

@media (max-width: 480px) {
	.button__login,
	.button__sign-up {
		font-size: 1.2rem;
		padding: 0.7rem 1.8rem;
	}
}
