.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .error-message-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--gray);
    color: var(--white);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 400px;
    width: 100%;
  }
  
  .error-icon {
    font-size: 40px;
  }
  
  .error-text {
    font-size: 2rem;
    text-align: center;
    margin: 10px 0;
  }
  
  .error-text-tip {
    font-size: 1.6rem;
    margin-top: 0.6rem;
    text-align: center;
  }
  
  .close-button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 1.6rem;
    background-color: var(--red);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .close-button:hover {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 1.6rem;
    background-color: var(--white);
    color: var(--gray);
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }