/* Subscription Page Styles */
.subscription-page {
  text-align: center;
  padding: 4rem 2rem;
  min-height: 100vh;
  max-width: 1200px;
  margin: 0 auto;
}

.subscription-page h1 {
  font-size: 3.6rem;
  margin-bottom: 4rem;
  background: linear-gradient(to right, var(--white) 20% 50%, var(--white) 80%);
  background-size: 200% auto;
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
}

.subscription-plans {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.4rem;
  margin: 0 auto;
  max-width: 1200px;
}

.subscription-plan {
  background: rgba(30, 41, 59, 0.2);
  border-radius: 12px;
  padding: 3.2rem 2.4rem;
  position: relative;
  transition: transform 0.3s ease, background-color 0.3s ease;
  cursor: pointer;
  overflow: hidden;
}

.subscription-plan::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: linear-gradient(90deg, 
    rgba(0, 229, 255, 0) 0%,
    rgba(0, 229, 255, 0.2) 50%,
    rgba(0, 229, 255, 0) 100%
  );
}

.subscription-plan:hover {
  background: rgba(248, 250, 252, 0.1);
  transform: translateY(-4px);
}

.subscription-plan.selected {
  background: rgba(0, 229, 255, 0.1);
  border: 1px solid rgba(0, 229, 255, 0.2);
}

.subscription-plan h3 {
  font-size: 2.4rem;
  color: var(--white);
  margin-bottom: 1.2rem;
  font-weight: 600;
}

.subscription-plan h5 {
  font-size: 3.2rem;
  color: var(--teal);
  margin-bottom: 2.4rem;
  font-weight: 700;
}

.subscription-plan h5 span {
  font-size: 1.6rem;
  color: var(--text-secondary);
  font-weight: 400;
}

.subscription-plan ul {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: left;
}

.subscription-plan li {
  color: var(--text-secondary);
  font-size: 1.4rem;
  line-height: 1.6;
  margin-bottom: 1.2rem;
  display: flex;
  align-items: center;
}

.subscription-plan li::before {
  content: '•';
  color: var(--teal);
  font-size: 2rem;
  margin-right: 1rem;
}

.checkout-button {
  margin-top: 4rem;
  padding: 1.2rem 4rem;
  font-size: 1.6rem;
  color: var(--white);
  background: linear-gradient(135deg, var(--teal) 0%, var(--blue) 100%);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 500;
  min-width: 200px;
}

.checkout-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 229, 255, 0.2);
}

.checkout-button:active {
  transform: translateY(0);
}

@keyframes shine {
  0% {
    background-position: 200% center;
  }
  100% {
    background-position: -200% center;
  }
}

/* Mobile Friendly Adjustments */
@media (max-width: 1024px) {
  .subscription-plans {
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    padding: 0 2rem;
  }
}

@media (max-width: 768px) {
  .subscription-page {
    padding: 3rem 1.6rem;
  }

  .subscription-plans {
    grid-template-columns: 1fr;
    max-width: 480px;
  }

  .subscription-plan {
    padding: 2.4rem 2rem;
  }

  .subscription-page h1 {
    font-size: 3rem;
    margin-bottom: 3rem;
  }

  .checkout-button {
    width: 100%;
    max-width: 480px;
  }
}
