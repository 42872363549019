.newsletter-signup {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    background-color: var(--background-color);
    border-radius: 8px;
  }
  
  .newsletter-signup__title {
    font-size: 2.5rem;
    font-weight: bold;
    color: var(--primary-color);
    margin-bottom: 1.5rem;
    text-align: center;
  }
  
  .newsletter-signup__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 600px;
  }
  
  .newsletter-signup__input {
    margin-top: 20px;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    width: 100%;
    max-width: 400px;
    height: 4rem;
    text-align: center;
    font-size: 1.5rem;
    color: rgba(55, 65, 81, 1);
    margin-bottom: 1rem;
  }
  
  .newsletter-signup__button {
    margin-top: 20px;
    padding: 15px 30px;
    border: none;
    border-radius: 8px;
    background-color: var(--blue);
    color: white;
    font-size: 1.5rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .newsletter-signup__button:hover {
    background-color: #0056b3;
  }
  
  .newsletter-signup__message {
    text-align: center;
    font-size: 1.7rem;
    color: var(--green);
    margin-top: 3rem;
  }