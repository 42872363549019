.stock__select__content-layout {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px;
    gap: 20px; /* Space between elements */
}

.stock__select__content-layout input {
    padding: 15px;
    border: 1px solid var(--white);
    border-radius: 8px;
    width: 250px;
    text-align: center;
    font-size: 1.2rem;
    color: rgba(55, 65, 81, 1);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: border 0.3s;
}

.stock__select__content-layout input:focus {
    border-color: var(--blue);
    outline: none;
}

.stock__select__content-layout button {
    padding: 12px 25px;
    border: none;
    border-radius: 8px;
    background-color: var(--blue);
    font-size: 1.2rem;
    font-weight: bold;
    color: #ffffff;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
    width: 15rem;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.stock__select__content-layout button:hover {
    background-color: var(--white);
    color: var(--gray);
    transform: scale(1.05);
}

.divider {
    padding-top: 2rem;
    font-size: 1.5rem;
    color: #ffffff;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 1px;
    margin: 10px 0;
}
