.custom-select {
    display: inline-block;
    width: 50px;
    height: 25px;
    margin: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    color: #333;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    min-width: 5rem;
    max-width: 5rem;
}

.custom-select select {
    width: 100%;
    height: 100%;
    border: none;
    box-shadow: none;
    background: transparent;
    background-image: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 8px 16px;
    font-size: 16px;
    color: #333;
    cursor: pointer;
}

.custom-select:after {
    content: "\25BC";
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    color: #333;
    pointer-events: none;
    font-size: 16px;
}

.custom-select:hover {
    border-color: #888;
}

.custom-select select:focus {
    outline: none;
}

/* Responsive adjustments */
@media only screen and (max-width: 960px) {
    .custom-select {
        min-width: 5rem;
        max-width: 5rem;
    }
}

@media only screen and (max-width: 480px) {
    .custom-select {
        width: 100%; /* Full width for small screens */
    }
}