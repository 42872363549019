.filter-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1.2rem;
    margin: 0 auto;
    margin-bottom: 2rem;
    padding: 1.2rem 1.5rem;
    background: var(--darker-blue);
    border-radius: 12px;
    max-width: 1300px;
    width: 100%;
}

.filter-container p {
    margin: 0;
    font-size: 1.4rem;
    font-weight: 500;
    color: var(--text-secondary);
    text-transform: uppercase;
    letter-spacing: 0.05em;
}

.filter-select {
    padding: 0.8rem 1.2rem;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    background-color: rgba(32, 36, 47, 0.6);
    color: var(--white);
    font-size: 1.6rem;
    cursor: pointer;
    transition: all 0.2s ease;
    min-width: 200px;
}

.filter-select:focus {
    outline: none;
    border-color: var(--blue);
    box-shadow: 0 0 0 1px var(--blue);
}

/* Responsive styles */
@media (max-width: 768px) {
    .filter-container {
        padding: 1rem;
        margin-bottom: 1.5rem;
    }

    .filter-container p {
        font-size: 1.2rem;
    }

    .filter-select {
        font-size: 1.4rem;
        padding: 0.6rem 1rem;
        min-width: 180px;
    }
}

@media (max-width: 480px) {
    .filter-container {
        flex-direction: column;
        align-items: stretch;
        gap: 0.8rem;
        padding: 0.8rem;
    }

    .filter-container p {
        font-size: 1.1rem;
        text-align: center;
    }

    .filter-select {
        width: 100%;
        min-width: unset;
        font-size: 1.3rem;
        padding: 0.5rem 0.8rem;
    }
}