.ai-analysis-container {
    font-family: "Arial", sans-serif;
    padding: 20px;
    border-radius: 8px;
}

.analysis-content {
    line-height: 1.6;
}

.analysis-paragraph {
    font-family: "Courier New", Courier, monospace;
    white-space: nowrap;
    overflow: hidden;
    border-right: 3px solid;
    width: 0;
    color: #f5f5f5;
    font-size: 2rem;
    animation: typing 3.5s steps(40, end), blink-caret 0.75s step-end infinite;
}



.loading-container {
    font-size: 2rem;
    text-align: left;
    margin-top: 1vh;
    padding: 20px;
}

@media (max-width: 768px) {
    .loading-container {
        font-size: 1.5rem;
        text-align: center;
        padding: 15px;
    }
}

@media (max-width: 480px) {
    .loading-container {
        font-size: 1.2rem;
        text-align: center;
        padding: 10px;
    }
}


.yearly-analysis {
    list-style-type: none;
    padding-left: 20px;
}

.yearly-analysis li {
    margin-bottom: 10px;
}

.overall-assessment {
    margin-top: 20px;
}

.coming-soon-text {
    font-size: 2rem;
    color: #f5f5f5;
    text-align: center;
}

@media only screen and (max-width: 900px) {
    .analysis-container {
        font-size: 1.5rem;
		height: 30vh;
    }

    .analysis-line {
        font-size: 1.4rem;
        margin-bottom: 8px;
    }

    .ai-analysis-container {
        padding: 10px;
    }
}

@media only screen and (max-width: 600px) {
    .analysis-container {
        font-size: 1.2rem;
    }

    .analysis-line {
        font-size: 1.2rem;
        margin-bottom: 0px;
    }

    .ai-analysis-container {
        padding: 8px;
    }
}

@keyframes typing {
    from { width: 0; }
    to { width: 100%; }
}

@keyframes blink-caret {
    from, to { border-color: transparent; }
    50% { border-color: #f5f5f5; }
}

.analysis-container {
    color: #f5f5f5;
    font-size: 1.7rem;
}

.analysis-line {
    display: block; 
    white-space: nowrap;
    overflow: hidden;
    width: 0;
    animation: typing 1s steps(40, end) forwards, blink-caret 0.75s step-end infinite;
}

/* Adjust animation delays as needed */
.analysis-line:nth-child(1) {
	animation-delay: 0s;
}

.analysis-line:nth-child(2) {
	animation-delay: 1s;
}

.analysis-line:nth-child(3) {
	animation-delay: 2s;
}

.analysis-line:nth-child(4) {
	animation-delay: 3s;
}

.analysis-line:nth-child(5) {
	animation-delay: 4s;
}

.analysis-line:nth-child(6) {
	animation-delay: 5s;
}

.analysis-line:nth-child(7) {
	animation-delay: 6s;
}

.analysis-line:nth-child(8) {
	animation-delay: 7s;
}
.analysis-line:nth-child(9) {
	animation-delay: 8s;
}

.analysis-line:nth-child(10) {
	animation-delay: 9s;
}

.analysis-line:nth-child(11) {
	animation-delay: 10s;
}

.analysis-line:nth-child(12) {
	animation-delay: 11s;
}

.analysis-line:nth-child(13) {
	animation-delay: 12s;
}