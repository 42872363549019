.error-message {
    text-align: left;
    color: var(--red);
    font-weight: 50;
    margin-left: 0.7vw;
    margin-bottom: 2.5vh;
}

.daily_price_loading {
    text-align: left;
    padding: 20px;
}

.daily_price_analysis-content {
    border-radius: 8px;
    margin: 20px 0;
}

.price_title {
    font-size: 1em;
    color: #333;
}

.stock-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    margin-bottom: 2.4rem;
    padding: 1.6rem;
    border-radius: 12px;
}

.stock-ticker {
    font-size: 2.4rem;
    font-weight: 600;
    color: var(--white);
    margin: 0;
}

.company-details {
    font-size: 1.6rem;
    color: var(--text-secondary);
    margin: 0;
    text-align: center;
}

.stock-price {
    font-size: 0.6em;
    color: var(--green);
    margin-left: 0.7vw;
    margin-top: 0.5vh;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.loading-header {
    font-size: 1.05em;
    display: flex;
    position: relative;
    align-items: center;
}

.skeleton-text {
    width: 100%;
    max-width: 400px;
}

.skeleton-line {
    height: 1.6rem;
    background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0.1) 25%,
        rgba(255, 255, 255, 0.2) 50%,
        rgba(255, 255, 255, 0.1) 75%
    );
    background-size: 200% 100%;
    border-radius: 4px;
    animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
    0% {
        background-position: 200% 0;
    }
    100% {
        background-position: -200% 0;
    }
}