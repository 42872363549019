.filter-container {
    display: flex;
    flex-direction: row;
    margin: 4rem;
    gap: 1rem;
    margin-bottom: 1.5rem;
    padding: 1rem;
    background-color: var(--light-gray);
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    flex-wrap: wrap;
    justify-content: space-between;
}

.filter-container label {
    display: flex;
    flex-direction: column;
    color: var(--white);
    margin-bottom: 0.5rem;
    flex: 1;
    min-width: 150px; /* Adjust as needed */
}

.filter-container select {
    padding: 0.5rem;
    border: 1px solid var(--lighter-gray);
    border-radius: 4px;
    font-size: 1rem;
    margin-top: 0.5rem;
    background-color: var(--white);
    color: var(--black);
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.filter-container select:focus {
    border-color: var(--blue);
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.filter-container select:hover {
    border-color: var(--dark-gray);
}