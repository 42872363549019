.financial-summary-container {
    display: block;
    color: var(--white);
    transition: all 0.3s ease-in-out 0s;
    width: 100%;
    text-align: left;
    padding: 1.5rem;
    margin-top: 2vh;
}

.financial-summary-container ul {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    padding: 0;
    margin: 0;
    list-style: none;
    justify-content: space-between; /* Distribute items evenly */
}

.financial-summary__description {
    margin: 0;
    color: var(--white);
    font-size: 1.7rem;
    line-height: 2.7rem;
    flex: 0 1 auto; /* Adjust the width of each item based on content */
    white-space: nowrap; /* Prevent text from wrapping */
    overflow: hidden; /* Hide overflow text */
    text-overflow: ellipsis; /* Add ellipsis for overflow text */
}

.financial-summary__label {
    margin: 0;
    color: var(--white);
    font-size: 1.5rem;
    line-height: 2.7rem;
    flex: 0 1 auto; /* Adjust the width of each item based on content */
    white-space: nowrap; /* Prevent text from wrapping */
    overflow: hidden; /* Hide overflow text */
    text-overflow: ellipsis; /* Add ellipsis for overflow text */
}

.financial-summary__overall-score {
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 3vh;
}

.red-metric {
    color: var(--red);  
}

.orange-metric {
    color: var(--orange);  
}

.green-metric {
    color: var(--green); 
}

.stock-explanation {
    margin-top: 4rem;
}

.explanation-subtitle {
    font-size: 1.8rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
    color: var(--white);
}

.explanation-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.explanation-item {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    color: var(--white);
}

.explanation-score {
    font-size: 1.6rem;
    margin-top: 2rem;
    color: var(--white);
}

.explanation-text {
    font-size: 1.2rem;
    white-space: pre-wrap; /* Preserve whitespace and line breaks */
    color: var(--white);
}

.explanation-table {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    text-align: center;
}

.explanation-row {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0;
}

.explanation-row.header {
    font-weight: bold;
}

.explanation-cell {
    flex: 1;
    padding: 0.1rem;
    color: var(--white);
}

.explanation-tables-container {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
}

.explanation-table-wrapper {
    flex: 1;
}

@media only screen and (max-width: 900px) {
    .financial-summary-container {
        padding: 3.2rem;
        border-radius: 0.8rem;
        width: 100%;
    }

    .financial-summary__description {
        font-size: 1.7rem;
        line-height: 2.2rem;
        flex: 1 1 100%; /* Stack items vertically on smaller screens */
    }

    .explanation-tables-container {
        flex-direction: column;
    }
}

@media only screen and (max-width: 480px) {
    .financial-summary-container {
        padding: 2.4rem;
        border-radius: 0.8rem;
        width: 100%;
    }

    .financial-summary__description {
        font-size: 1.5rem;
        line-height: 2rem;
        flex: 1 1 100%; /* Stack items vertically on smaller screens */
    }

    .explanation-tables-container {
        flex-direction: column;
    }
}