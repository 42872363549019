.navigation-tabs {
    display: flex;
    justify-content: center;
    border-bottom: 2px solid #ddd;
}

.yahoo_finance_link {
    width: 85px;
    max-width: 125px;
    height: 42px;
    border-radius: 10px;
    box-sizing: border-box;
    background-color: var(--white);
    position: relative; 
    top: 5.5vh;
    left: 1050px;
}

.yahoo_image {
    width: 100%; 
    height: 100%;
    opacity: 80%;
    padding: 8px;
    box-sizing: border-box;
}

.open-tab_image{
    position: absolute;
    width: 15%;
    top: 1.3vh;
    left: 7.4vw; 
    opacity: 60%;
    box-sizing: border-box;
}

.yahoo_text {
    font-size: 1.5rem;
    color: var(--black);
    position: relative;
    text-align: center;    
}

.tab {
    padding: 0.5rem 1rem;
    text-decoration: none;
    color: #333;
    margin-right: 1rem;
    transition: all 0.3s ease;
    border-radius: 5px;
    background-color: #f8f8f8;
}

.tab:hover {
    background-color: #e8e8e8;
    color: #000;
}

.tab.active {
    background-color: #fff;
    border-bottom: 2px solid #666;
    color: #000;
    margin-bottom: -2px;
}

.tab:last-child {
    margin-right: 0;
}

@media (max-width: 1220px) {
    .yahoo_finance_link {
        display: none;
    }
}

@media (max-width: 480px) {
    .yahoo_finance_link {
        display: none;
    }
}