.home-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--background-dark);
    color: var(--white);
    padding: 0 24px;
}

.hero-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 1200px;
    margin: 3vh auto;
    padding: 0 24px;
}

.hero-title {
    font-size: 4rem;
    font-weight: 700;
    line-height: 1.2;
}

.gradient-text {
    background: var(--text-gradient);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

.gradient-text2 {
    background: var(--text-gradient-secondary);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

.hero-subtitle {
    font-size: rem;
    font-weight: 500;
    margin-bottom: 3vh;
    color: var(--text-secondary);
}

.hero-description {
    font-size: 1.75rem;
    max-width: 600px;
    margin-bottom: 48px;
    color: var(--text-secondary);
    line-height: 1.6;
}

.search-bar-container {
    width: 100%;
    max-width: 600px;
}
.hero-banner__headline__3{
    font-size: 1.7rem;
    padding-top: 1vh;
    color: var(--text-secondary);
    line-height: 1.6;
}

.search-wrapper {
    display: flex;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    overflow: hidden;
    transition: border-color 0.1s ease;
}

.search-wrapper:focus-within {
    border-color: var(--text-primary);
}

.search-input {
    flex: 1;
    background: transparent;
    border: none;
    padding: 16px 24px;
    color: var(--text-primary);
    font-size: clamp(1.25rem, 2vw, 1.75rem);
    width: 100%;
    height: 4vh;
}

.search-input:focus {
    outline: none;
}

.search-input::placeholder {
    color: var(--text-secondary);
    font-size: clamp(1.25rem, 2vw, 1.75rem);
    opacity: 0.7;
}

.search-button {
    background: var(--primary-gradient);
    border: none;
    border-radius: 0;
    padding: 16px 32px;
    color: white;
    cursor: pointer;
    transition: opacity 0.2s;
    border-left: 1px solid rgba(255, 255, 255, 0.1);
    min-width: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.search-button svg {
    width: 1.5em;
    height: 1.5em;
}

.search-button:hover {
    opacity: 0.9;
}

.cta-section {
    text-align: center;
    margin: 48px 0;
}

.cta-text {
    font-size: 1.25rem;
    color: var(--text-secondary);
    margin-bottom: 32px;
    line-height: 1.6;
}

.cta-buttons {
    display: flex;
    gap: 16px;
    justify-content: center;
}

.primary-button {
    background: var(--primary-gradient);
    border: none;
    border-radius: 8px;
    padding: 16px 32px;
    color: white;
    font-size: 1.8rem;
    font-weight: 600;
    cursor: pointer;
    transition: opacity 0.2s;
}

.secondary-button {
    background: transparent;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    padding: 16px 32px;
    color: var(--white);
    font-size: 1.8rem;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.2s;
    text-decoration: none;
}

.primary-button:hover {
    opacity: 0.9;
}

.secondary-button:hover {
    background: rgba(255, 255, 255, 0.1);
}

.features-section {
    width: 100%;
    max-width: 1200px;
    margin: 80px auto;
}

.features-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 32px;
    padding: 0 24px;
}

.feature-card {
    background: var(--card-background);
    border-radius: 16px;
    padding: 32px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    transition: transform 0.2s;
}


.feature-icon {
    width: 65px;
    height: 65px;
    background: var(--primary-gradient);
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
}

.feature-icon svg {
    width: 24px;
    height: 24px;
    color: white;
}

.feature-card h3 {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 16px;
    color: var(--white);
}

.feature-card p {
    font-size: 1.5rem;
    color: var(--text-secondary);
    line-height: 1.6;
    margin-bottom: 24px;
}

.learn-more {
    color: #00E5FF;
    font-size: 1.8rem;
    text-decoration: none;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    transition: opacity 0.2s;
}

.learn-more:hover {
    opacity: 0.8;
    color: var(--white);
}

@media (max-width: 768px) {
    .hero-title {
        font-size: 3rem;
    }

    .hero-subtitle {
        font-size: 2rem;
    }

    .features-grid {
        grid-template-columns: 1fr;
    }

    .cta-buttons {
        flex-direction: column;
    }

    .search-wrapper {
        flex-direction: column;
        gap: 16px;
    }

    .search-button {
        width: 100%;
    }
}

@media (max-width: 480px) {
    .hero-title {
        font-size: 2.5rem;
    }

    .hero-subtitle {
        font-size: 1.5rem;
    }

    .hero-description {
        font-size: 1rem;
    }
}