.tanstack-table-container {
	position: relative;
    overflow-x: hidden;
    border-radius: 12px;
    padding: 1px;
    background: rgba(30, 41, 59, 0.2);
}

.tanstack-table {
    margin: 0 auto;
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;
    max-width: 1400px;
    color: var(--text-secondary);
    background: transparent;
    border-radius: 12px;
}

.tanstack-table th,
.tanstack-table td {
    user-select: none;
    padding: 1.8rem 1rem;
    text-align: center;
    font-size: 1.4rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.tanstack-table th {
    background: rgba(30, 41, 59, 0.4);
    font-weight: 500;
    font-size: 1.2rem;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    color: var(--text-secondary);
    padding: 1.4rem 1rem;
    position: sticky;
    top: 0;
    transition: background-color 0.2s;
    text-align: center;
}

.tanstack-table th:hover {
    background-color: rgba(248, 250, 252, 0.1);
    cursor: pointer;
    color: var(--white);
}

.tanstack-table tbody tr {
    transition: all 0.3s ease;
}

.tanstack-table tbody tr:nth-child(odd) {
    background-color: transparent;
}

.tanstack-table tbody tr:nth-child(even) {
    background-color: rgba(30, 41, 59, 0.2);
}

.tanstack-table tbody tr:hover {
    background: rgba(248, 250, 252, 0.1);
    cursor: pointer;
}

.tanstack-table tbody tr:hover td {
    color: rgba(248, 250, 252, 0.9);
}

.tanstack-table td {
    font-size: 1.6rem;
    font-weight: 400;
}

/* Column specific styles */
.tanstack-table td:first-child,
.tanstack-table th:first-child {
    padding-left: 2rem;
}

.tanstack-table td:last-child,
.tanstack-table th:last-child {
    padding-right: 2rem;
}

/* Metric colors matching StockFilingCard */
.red-metric {
    color: var(--red);
    font-weight: 500;
}

.orange-metric {
    color: var(--orange);
    font-weight: 500;
}

.green-metric {
    color: var(--green);
    font-weight: 500;
}

/* Add top border glow effect like StockFilingCard */
.tanstack-table-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: linear-gradient(90deg, 
        rgba(0, 229, 255, 0) 0%,
        rgba(0, 229, 255, 0.2) 50%,
        rgba(0, 229, 255, 0) 100%
    );
}

/* Responsive styles */
@media (max-width: 768px) {
    .tanstack-table-container {
        padding: 0;
        border-radius: 8px;
    }
    
    .tanstack-table th,
    .tanstack-table td {
        padding: 1.4rem 1rem;
        font-size: 1.3rem;
    }

    .tanstack-table th {
        font-size: 1.1rem;
    }
}

@media (max-width: 480px) {
    .tanstack-table {
        margin: 0;
        border-radius: 6px;
    }

    .tanstack-table th,
    .tanstack-table td {
        padding: 1.2rem 0.8rem;
        font-size: 1.2rem;
    }

    .tanstack-table th {
        font-size: 1rem;
    }
    
    .tanstack-table td:first-child,
    .tanstack-table th:first-child {
        padding-left: 1.2rem;
    }

    .tanstack-table td:last-child,
    .tanstack-table th:last-child {
        padding-right: 1.2rem;
    }
}