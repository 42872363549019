.stockFilingCard-container {
    width: 100%;
    color: var(--text-secondary);
    background: rgba(30, 41, 59, 0.2);
    border-radius: 0.8rem;
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease;
}

.stockFilingCard-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: linear-gradient(90deg, 
        rgba(0, 229, 255, 0) 0%,
        rgba(0, 229, 255, 0.2) 50%,
        rgba(0, 229, 255, 0) 100%
    );
}

.stockFilingCard-container:hover {
    background: rgba(248, 250, 252, 0.1);
}

.stockFilingCard-container:hover .stockFilingCard-title {
    background: var(--text-gradient-secondary);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.stockFilingCard-container:hover .stockFilingCard-header,
.stockFilingCard-container:hover .stockFilingCard-paragraph {
    color: rgba(248, 250, 252, 0.9);
}

.stockFilingCard-link {
    text-decoration: none;
    color: inherit;
    display: block;
}

.stockFilingCard-content {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
}

/* Header Section */
.stockFilingCard-header-section {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding-bottom: 1.6rem;
}

.stockFilingCard-title-row {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 0.8rem;
}

.stockFilingCard-position {
    font-size: 1.8rem;
    font-weight: 600;
    color: var(--blue);
    background: rgba(0, 200, 227, 0.1);
    padding: 0.4rem 0.8rem;
    border-radius: 0.4rem;
}

.stockFilingCard-title {
    font-size: 2rem;
    font-weight: 600;
    color: var(--white);
    margin: 0;
}

.stockFilingCard-meta {
    display: flex;
    align-items: center;
    gap: 1.2rem;
    margin-bottom: 0.8rem;
}

.stockFilingCard-ticker {
    font-size: 1.4rem;
    font-weight: 500;
    color: var(--blue);
    background: rgba(0, 200, 227, 0.1);
    padding: 0.2rem 0.6rem;
    border-radius: 0.3rem;
}

.stockFilingCard-date {
    font-size: 1.4rem;
    color: var(--text-secondary);
}

.stockFilingCard-industry {
    font-size: 1.4rem;
    color: var(--text-secondary);
    margin: 0;
}

/* Scores Section */
.stockFilingCard-scores-section {
    padding: 1.2rem 0;
}

.stockFilingCard-score-group {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.2rem;
}

.stockFilingCard-score-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.4rem;
}

.score-label {
    font-size: 1.2rem;
    color: var(--text-secondary);
    text-transform: uppercase;
    letter-spacing: 0.05em;
}

.score-value {
    font-size: 1.8rem;
    font-weight: 600;
}

/* Metrics Section */
.stockFilingCard-metrics-section {
    padding: 1.2rem 0;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.stockFilingCard-metric-group {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.2rem;
}

.metric-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.4rem;
}

.metric-label {
    font-size: 1.2rem;
    color: var(--text-secondary);
    text-transform: uppercase;
    letter-spacing: 0.05em;
}

.metric-value {
    font-size: 1.6rem;
    font-weight: 500;
}

/* Footer Section */
.stockFilingCard-footer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.2rem;
    padding-top: 1.2rem;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.footer-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.4rem;
}

.footer-label {
    font-size: 1.2rem;
    color: var(--text-secondary);
    text-transform: uppercase;
    letter-spacing: 0.05em;
}

.footer-value {
    font-size: 1.4rem;
    font-weight: 500;
}

.footer-value.recommendation {
    padding: 0.2rem 0.6rem;
    border-radius: 0.3rem;
    text-transform: capitalize;
}

.footer-value.recommendation.buy {
    background: rgba(34, 197, 94, 0.2);
    color: var(--green);
}

.footer-value.recommendation.neutral {
    background: rgba(234, 179, 8, 0.2);
    color: var(--orange);
}

.footer-value.recommendation.sell {
    background: rgba(239, 68, 68, 0.2);
    color: var(--red);
}

.footer-value.positive {
    color: var(--green);
}

.footer-value.negative {
    color: var(--red);
}

/* Color Classes */
.red-metric {
    color: var(--red);
}

.orange-metric {
    color: var(--orange);
}

.green-metric {
    color: var(--green);
}

/* Responsive Design */
@media only screen and (max-width: 640px) {
    .stockFilingCard-content {
        padding: 1.6rem;
        gap: 1.2rem;
    }

    .stockFilingCard-title {
        font-size: 1.8rem;
    }

    .stockFilingCard-score-group,
    .stockFilingCard-metric-group,
    .stockFilingCard-footer {
        grid-template-columns: repeat(2, 1fr);
    }

    .score-value {
        font-size: 1.6rem;
    }

    .metric-value {
        font-size: 1.4rem;
    }
}
