.profile-menu {
    position: relative;
    margin-left: 0.8rem;
}

.profile-menu__trigger {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.profile-menu__avatar {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    border: 2px solid var(--teal);
    transition: all 0.2s ease;
    object-fit: cover;
}

.profile-menu__avatar-placeholder {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background: var(--darker-blue);
    border: 2px solid var(--teal);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white);
    font-weight: 600;
    font-size: 1.8rem;
    transition: all 0.2s ease;
}

.profile-menu__trigger:hover .profile-menu__avatar,
.profile-menu__trigger:hover .profile-menu__avatar-placeholder {
    transform: scale(1.05);
    border-color: var(--lighter-teal);
}

.profile-menu__dropdown {
    position: absolute;
    top: calc(100% + 0.8rem);
    right: 0;
    background: var(--darker-blue);
    border: 1px solid var(--border-color);
    border-radius: 8px;
    padding: 1.2rem;
    min-width: 260px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
    opacity: 0;
    visibility: hidden;
    transform: translateY(-8px);
    transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    z-index: 1000;
    backdrop-filter: blur(8px);
    background: linear-gradient(
        to bottom,
        rgba(30, 41, 59, 0.98) 0%,
        rgba(30, 41, 59, 0.95) 100%
    );
}

.profile-menu__dropdown::before {
    content: '';
    position: absolute;
    top: -6px;
    right: 20px;
    width: 12px;
    height: 12px;
    background: linear-gradient(135deg, rgba(30, 41, 59, 0.98) 50%, transparent 50%);
    transform: rotate(45deg);
    border-left: 1px solid var(--border-color);
    border-top: 1px solid var(--border-color);
    z-index: -1;
}

.profile-menu__dropdown.open {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
}

.profile-menu__user-info {
    padding: 0.8rem 0.4rem 1.2rem 0.4rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    margin-bottom: 0.8rem;
}

.profile-menu__name {
    font-weight: 600;
    color: var(--white);
    font-size: 1.5rem;
    margin-bottom: 0.4rem;
}

.profile-menu__email {
    color: var(--text-secondary);
    font-size: 1.3rem;
    word-break: break-all;
}

.profile-menu__item {
    display: flex;
    align-items: center;
    padding: 1rem 1.2rem;
    color: var(--white);
    text-decoration: none;
    border-radius: 6px;
    transition: all 0.2s ease;
    font-size: 1.4rem;
    margin: 0.2rem 0;
    width: 100%;
    background: none;
    border: none;
    cursor: pointer;
    text-align: left;
}

.profile-menu__item:hover {
    background: rgba(248, 250, 252, 0.1);
}

.profile-menu__item svg {
    margin-right: 1.2rem;
    width: 1.6rem;
    height: 1.6rem;
    opacity: 0.8;
}

.profile-menu__item--logout {
    margin-top: 0.4rem;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    padding-top: 1.2rem;
    color: var(--red);
}

.profile-menu__item--logout svg {
    color: var(--red);
}

@keyframes dropdownOpen {
    from {
        opacity: 0;
        transform: translateY(-8px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
} 