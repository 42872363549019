/* Main container */
.success-display {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: 0 auto;
}

/* Product box */
.product {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

/* Description */
.description {
    text-align: center;
}

.description h3 {
    margin: 0;
    font-size: 24px;
    color: #333;
}

/* Form */
form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Button */
#checkout-and-portal-button {
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: #6772e5;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

#checkout-and-portal-button:hover {
    background-color: #5469d4;
}

/* Loading and error messages */
.loading-message,
.error-message {
    margin-top: 20px;
    font-size: 2rem;
    color: #333;
    color: var(--red);
    text-align: center;
}
