.detail-container {
    display: block;
    color: var(--white);
    transition: all 0.3s ease-in-out 0s;
    width: 100%;
    text-align: left;
    padding: 1.5rem;
    overflow-x: auto; /* Enable horizontal scrolling */
    white-space: nowrap; /* Prevent text from wrapping */
    scrollbar-width: thin; /* For Firefox */
    scrollbar-color: var(--white) var(--black); /* For Firefox */
    overflow: hidden;

}

.detail-container ul {
    display: flex;
    flex-direction: column; /* Align items in a single column */
    gap: 1rem;
    padding: 0;
    margin: 0;
    list-style: none;
}

.detail__year {
    font-size: 2rem;
    font-weight: bold;
    color: var(--white);
    margin-bottom: 1rem;
    text-align: left;
    width: 100%;
}

.detail__description {
    margin: 0;
    color: var(--white);
    font-weight: 500;
    line-height: 1vh;
    white-space: nowrap; /* Prevent text from wrapping */
    text-overflow: ellipsis; /* Add ellipsis for overflow text */
}

.detail__description__label{
    font-size: 1.4rem;
    color: var(--lighter-gray);
}

.detail__description__value{
    font-size: 1.5rem;
    padding-left: 10px;
}

.detail__description__change{
    font-size: 1.5rem;
}


.positive-difference {
    color: var(--green);  
    font-size: 0.9em; 
}

.negative-difference {
    color: var(--red); 
    font-size: 0.9em; 
}

.neutral-difference {
    color: var(--black); 
    font-size: 0.9em; 
}

@media only screen and (max-width: 900px) {
    .detail-container {
        padding: 3.2rem;
        border-radius: 0.8rem;
        width: 100%;
    }

    .detail__description {
        font-size: 1.7rem;
        line-height: 2.2rem;
        flex: 1 1 100%; /* Stack items vertically on smaller screens */
    }
}

@media only screen and (max-width: 480px) {
    .detail-container {
        padding: 2.4rem;
        border-radius: 0.8rem;
        width: 100%;
    }

    .detail__description {
        font-size: 1.5rem;
        line-height: 2rem;
        flex: 1 1 100%; /* Stack items vertically on smaller screens */
    }

    .detail__description__change {
        font-size: 1.3rem;
    }

    .positive-difference,
    .negative-difference,
    .neutral-difference {
        font-size: 1em;
    }
}