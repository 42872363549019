.nav-bar__container {
    position: fixed;
    display: flex;
    justify-content: center;
    flex-shrink: 0;
    width: 100%;
    box-shadow: rgb(90 95 102) 0-1.5px 0 inset;
    z-index: 300;
    background-color: var(--gray); /* Add background color */
}

.nav-bar {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
    height: 6vh;
    min-height: 65px;
    max-width: 1500px;
    padding: 0 24px;
    margin: 0;
}

.nav-bar__brand {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    flex: 0 0 auto;
}

.nav-bar__brand-content {
    display: flex;
    align-items: center;
	white-space: nowrap;
}

.nav-bar__logo {
	position: relative;
	left: 0.7vw;
	top: 0.5vh;
    height: 36px;
	/* margin-left: 0.6rem; */
	/* margin-top: 2rem; */
}

.nav-bar__link {
    display: flex;
    align-items: center;
    height: 100%;
}

.nav-bar__brand .header-title {
    display: none;
    font-size: 2.3rem; /* equivalent to text-2xl */
    font-weight: 450; /* equivalent to font-semibold */
    position: relative;
    top: 0.5vh;
    left: 0.7vw;
}

.nav-bar__brand .header-title span {
    font-size: 1.0rem; /* equivalent to text-sm */
    font-weight: 300; /* equivalent to font-light */
    opacity: 0.7;
    position: relative;
    top: -1vh;
    left: 0.3vw;
}

@media (min-width: 640px) { /* equivalent to sm:block */
    .nav-bar__brand .header-title {
        display: block;
    }
}

.nav-bar__tabs {
    flex: 1;
    display: flex;
    justify-content: center;
    gap: 32px;
}

.nav-bar__tab {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
}

.nav-bar__tab:last-child {
    margin-right: 0;
}

.nav-bar__tab--active {
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-color: var(--teal);
    text-decoration-thickness: 4px;
    text-underline-offset: 8px;
}

.nav-bar__tab:hover {
    color: var(--teal);
    transition: color 0.07s ease-in;
}

.nav-bar__icon {
    display: none;
}

.nav-bar__buttons {
    display: flex;
    flex: 0 0 auto;
    margin-left: 24px;
}

.nav-bar__search {
    display: flex;
    justify-content: center;
    width: 50rem;
    margin-left: 14rem;
    height: 50%;
}

.nav-bar__search input {
    border: 1px solid #ddd;
    border-radius: 8px 0 0 8px;
    width: 60%;
    text-align: center;
    font-size: 1.3rem;
    font-weight: 700;
    color: rgba(55, 65, 81, 1);
}

.nav-bar__search input::placeholder {
    color: rgba(0, 0, 0, 0.7); 
    font-weight: 500;
}

.nav-bar__search input:focus {
    border-color: var(--teal); 
    transform: scale(1.02); 
}

.nav-bar__search-button {
    padding: 0.7vh 1.5rem;
    border: 1px solid #ddd;
    border-left: none;
    border-radius: 0 8px 8px 0;
    background-color: var(--teal); 
    color: white;
    cursor: pointer;
    transition: background-color 0.1s, transform 0.1s;
}

.nav-bar__search-button:hover {
    background-color: var(--teal);
}

.nav-bar__search-button:focus {
    outline: none;
}

@media only screen and (max-width: 640px) and (hover: none) {
    .mobile-nav-bar__tab:hover {
        color: var(--white);
    }

    .mobile-nav-bar__tab--active:hover {
        color: var(--white);
    }
}

@media only screen and (max-width: 1220px) {
    .nav-bar__container {
        display: none;
    }

    .nav-bar__tab {
        font-size: 1.5rem;
    }

    .nav-bar {
        height: 6.4rem;
        padding: 0.8rem 1.6rem;
    }

    .nav-bar__brand {
        display: flex;
        align-items: center;
        margin-right: 1.6rem;
    }

    .nav-bar__logo {
		position: relative;
        height: 3.8rem;
        top: 0.6rem;
        left: 1.5vw;
    }

    .nav-bar__tab--active {
        text-decoration-line: none;
        color: var(--teal);
    }

    .nav-bar__label {
        display: none;
    }

    .nav-bar__icon {
        display: block;
        font-size: 3.2rem;
    }

    .nav-bar__buttons {
        margin-left: 24px;
    }
}

@media only screen and (max-width: 340px) {
    .nav-bar__tab {
        font-size: 1.3rem;
    }

    .nav-bar {
        height: 6.4rem;
        padding: 0.8rem 1.6rem;
    }

    .nav-bar__brand {
        display: flex;
        align-items: center;
        margin-right: 1.6rem;
    }

    .nav-bar__logo {
		position: relative;
        height: 3.2rem;
        top: 0.6rem;
        left: 1.5vw;
    }

    .nav-bar__tab--active {
        text-decoration-line: none;
        color: var(--teal);
    }

    .nav-bar__buttons {
        margin-left: 24px;
    }
}