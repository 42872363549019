/* Learn More Pages Styles */

.content-layout {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
}

.content__title {
    font-size: 3.6rem;
    margin-bottom: 4rem;
    background: var(--text-gradient);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
}

.content__body {
    display: flex;
    flex-direction: column;
    gap: 3.2rem;
}

.feature-section {
    background: rgba(30, 41, 59, 0.2);
    border-radius: 0.8rem;
    padding: 3.2rem;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
}

.feature-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: linear-gradient(90deg, 
        rgba(0, 229, 255, 0) 0%,
        rgba(0, 229, 255, 0.2) 50%,
        rgba(0, 229, 255, 0) 100%
    );
}

.feature-section:hover {
    background: rgba(30, 41, 59, 0.3);
}

.feature-section h2 {
    color: var(--white);
    font-size: 2.4rem;
    margin-bottom: 2rem;
    background: var(--text-gradient-secondary);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;
}

.feature-section p {
    color: var(--text-secondary);
    font-size: 1.6rem;
    line-height: 1.7;
    margin-bottom: 2rem;
    letter-spacing: 0.01em;
}

.feature-section ul,
.feature-section ol {
    color: var(--text-secondary);
    font-size: 1.6rem;
    line-height: 1.7;
    margin: 0;
    padding-left: 2.4rem;
}

.feature-section li {
    margin-bottom: 1rem;
    position: relative;
}

.feature-section li:last-child {
    margin-bottom: 0;
}

.feature-section ul li::marker {
    color: rgba(0, 229, 255, 0.6);
}

/* Responsive Design */
@media only screen and (max-width: 768px) {
    .content-layout {
        padding: 1.6rem;
    }

    .content__title {
        font-size: 2.8rem;
        margin-bottom: 3.2rem;
    }

    .feature-section {
        padding: 2.4rem;
    }

    .feature-section h2 {
        font-size: 2rem;
    }
}

@media only screen and (max-width: 480px) {
    .content-layout {
        padding: 1.2rem;
    }

    .content__title {
        font-size: 2.4rem;
        margin-bottom: 2.4rem;
    }

    .feature-section {
        padding: 2rem;
    }

    .feature-section h2 {
        font-size: 1.8rem;
    }

    .feature-section p,
    .feature-section ul,
    .feature-section ol {
        font-size: 1.4rem;
    }
} 